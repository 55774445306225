<template>
  <v-skeleton-loader type="list-item-three-line" v-if="isLoading" />

  <v-card class="card-contract" v-else>
    <div class="d-flex justify-space-between align-center mb-5">
      <div>
        <div
          :class="['b-chip color-outlined', getColor(model.situation)]"
          v-if="daysRemaining > 0"
        >
          {{ model.situation }}
        </div>
        <div v-else class="b-chip color-outlined error--text">Vencido</div>
      </div>
      <v-icon :color="color" size="48">
        {{ getProductTypeIcon(model.productType) }}
      </v-icon>
    </div>

    <div class="card-contract__date">
      <div>Vigência</div>
      <div>
        {{ formatDate(model.effectiveDate) }} até
        {{ formatDate(model.expirationDate) }}
      </div>
    </div>
    <v-progress-linear
      rounded
      height="8"
      :color="color"
      :class="daysRemaining <= 0 && 'mb-9'"
      v-model="percentageDays"
    />
    <p class="card-contract--days" v-if="daysRemaining > 0">
      {{ daysRemaining }} dias restantes para renovação
    </p>
    <v-btn
      :class="['color-outlined', `${color}--text`]"
      depressed
      block
      @click="redirect"
    >
      Visualizar contrato
    </v-btn>
  </v-card>
</template>

<script>
import { DashboardService } from '@/services/api/dashboard';
import { formatDate } from '@/helpers/formatting';
import {
  getProductTypeIcon,
  getProductTypeName,
  getColor
} from '@/helpers/utils';

export default {
  props: {
    contractId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    isLoading: false,
    model: {
      situation: null,
      productType: null,
      effectiveDate: null,
      expirationDate: null
    }
  }),

  computed: {
    daysRemaining() {
      if (!this.model.expirationDate) return 0;

      const day = new Date().getTime();
      const expirationDate = new Date(this.model.expirationDate).getTime();
      const difference = expirationDate - day;
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays;
    },

    daysBetweenDates() {
      if (!this.model.effectiveDate) return 0;

      const effectiveDate = new Date(this.model.effectiveDate).getTime();
      const expirationDate = new Date(this.model.expirationDate).getTime();
      const difference = expirationDate - effectiveDate;
      const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays;
    },

    percentageDays() {
      const percentage = (this.daysRemaining / this.daysBetweenDates) * 100;
      return 100 - percentage;
    },

    color() {
      const situation = this.model.situation;

      if (this.daysRemaining <= 30 || situation === 'Cancelado') {
        return 'error';
      }

      if (this.daysRemaining <= 90) {
        return 'warning';
      }

      return 'success';
    }
  },

  created() {
    this.search();
  },

  methods: {
    getProductTypeIcon,
    getProductTypeName,
    getColor,
    formatDate,

    async search() {
      try {
        this.isLoading = true;
        const dashboardService = new DashboardService(false);
        const { status, data } = await dashboardService.getContractCardData(
          this.contractId
        );

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    redirect() {
      this.$router.push({
        name: 'contratos-edit',
        params: { id: this.contractId }
      });
    }
  },

  watch: {
    contractId() {
      this.search();
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .card-contract {
    padding: 1.25rem;

    &__date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0 0.4rem;

      div {
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: $gray--40;
      }
    }

    &--days {
      color: $gray--30;
      font-size: 0.75rem;
      line-height: 1rem;
      margin-top: 8px;
    }
  }
}

.b-chip {
  height: 26px;
  font-size: 0.85rem;
  padding: 0 16px;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  font-weight: 400;
  max-width: 100%;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
